exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-builds-tsx": () => import("./../../../src/pages/builds.tsx" /* webpackChunkName: "component---src-pages-builds-tsx" */),
  "component---src-pages-iframe-tsx": () => import("./../../../src/pages/iframe.tsx" /* webpackChunkName: "component---src-pages-iframe-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-tracker-statistics-tsx": () => import("./../../../src/pages/tracker/statistics.tsx" /* webpackChunkName: "component---src-pages-tracker-statistics-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-item-tsx": () => import("./../../../src/templates/item.tsx" /* webpackChunkName: "component---src-templates-item-tsx" */)
}

